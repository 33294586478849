import React from "react"
import SearchEngineOptimisation from "../components/SearchEngineOptimisation"
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import '../styles/styles.scss'
import { Link } from 'gatsby'

export default function NotFoundPage({ data }) {
  return <div>
    <SearchEngineOptimisation index={false}/>
    <Hero />

    <div className="page-not-found container">
      <h1>Page not found.</h1>
      <p>Sorry, this page you requested doesn't exist.</p><br />
      <p>Maybe you can find what you're looking for on the <Link to="/">main page</Link>?</p>
    </div>

    <Footer />
  </div>
}